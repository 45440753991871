import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  listPatients,
  exportPatients as exportPatientsApi,
  exportEmergency as exportEmergencyApi,
  uploadBtlEmergencyFile,
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import { withNotifications } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "patients/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listPatients(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load patients", message: error })
      );
    }
    return data;
  }
);

export const uploadEmergencyFile = createAsyncThunk(
  "patients/uploadEmergencyFile",
  withNotifications(
    uploadBtlEmergencyFile,
    "upload_emergency_file",
    "upload_emergency_file_success",
    "upload_emergency_file_error"
  )
);

export const exportPatients = createAsyncThunk(
  "patients/export",
  withNotifications(
    exportPatientsApi,
    "export_patients",
    "export_patients_success",
    "export_patients_error"
  )
);

export const exportEmergency = createAsyncThunk(
  "patients/exportEmergency",
  withNotifications(
    exportEmergencyApi,
    "export_emergency_patients",
    "export_emergency_patients_success",
    "export_emergency_patients_error"
  )
);

const onItemChanged = (state, action) => {
  const changedItem = action.payload.data;
  const { items } = state.page;
  state.page.items = items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};
const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
      $or: [
        { status_id: 1 },
        { status_id: 3 },
        { status_id: 4 },
        {
          passing_date: {
            $gt: moment().subtract(1, "month").startOf("month").format(),
          },
        },
      ],
    },
    order: {
      field: "updated_at",
      order: "descend",
    },
    items: [],
    finalfilters: {
      status_id: [],
      gender_id: [],
    },
    fields:
      "first_name,surename,status_id,local_id_number,contact_info,birthday,gender_id,address,avatar_url,id,branch_id,branch",
  },
};

const patientsSlice = createSlice({
  name: "patients",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    reset(state, action) {
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },

    "patientDetails/activate/fulfilled": onItemChanged,
    "patientDetails/deactivate/fulfilled": onItemChanged,
    "patientDetails/update/fulfilled": onItemChanged,
    "patientDetails/delete/fulfilled": (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
  },
});

export const { setPage, reset } = patientsSlice.actions;

export default patientsSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const { finalfilters, order } = params;
  let newOrder = order;
  if (!order.order) {
    newOrder = initialState.page.order;
  }
  let newParams = { ...params, order: newOrder };
  if (finalfilters && !finalfilters.status_id) {
    const otherFilters = params.filter["$and"] ? params.filter["$and"] : [];
    newParams.filter = {
      $and: [{ $or: initialState.page.filter["$or"] }, ...otherFilters],
      freeText: params.filter.freeText,
    };
  }
  const newState = {
    ...getState().patients.page,
    ...newParams,
  };
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().patients.page));
};
