import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getclientsTariffs } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "clientsTariffs/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await getclientsTariffs();
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load tariffs", message: error }));
    }
    return data;
  }
);

const initialState = {
  items: [],
};

const tariffsSlice = createSlice({
  name: "clientsTariffs",
  initialState: initialState,
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.items = action.payload.items;
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default tariffsSlice.reducer;
