import React, { useEffect } from "react";
import "./index.css";
import "./assets/fonts/Rubik-Regular";
import "./assets/fonts/Rubik-Bold";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === "tenants") {
    return import("./TenantsApp.js");
  } else {
    return import("./App.js");
  }
}

const container = document.getElementById("root");
const root = createRoot(container);

// The new wrapper component that logs "rendered"
function AppWithCallbackAfterRender({ children }) {
  useEffect(() => {}, []);
  return children;
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) =>
  root.render(
    <Provider store={store}>
      <AppWithCallbackAfterRender>
        <Environment />
      </AppWithCallbackAfterRender>
    </Provider>
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
