import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listHouseVisits,
  getHouseVisitSettings,
  updateHouseVisitSettings,
  createHouseVisitSettings,
} from "../../api/server";
import {
  notifyError,
  withNotifications,
} from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "houseVisits/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listHouseVisits(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load houseVisits", message: error })
      );
    }
    return data;
  }
);

const onItemChanged = (state, action) => {
  const changedItem = action.payload.data;
  const { items } = state.page;
  state.page.items = items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};

export const fetchSettings = createAsyncThunk(
  "houseVisits/getSettings",
  withNotifications(
    async (params, thunkAPI) => {
      const { scopePrefix } = thunkAPI.getState().user;
      return getHouseVisitSettings({ scopePrefix, ...params });
    },
    "get_house_visit_settings",
    null,
    "get_house_visit_settings_error"
  )
);

export const commitChanges = createAsyncThunk(
  "houseVisits/updateSettings",
  withNotifications(
    async (params, thunkAPI) => {
      const {
        patientDetails: {
          activePatient: { id: patientId },
        },
        houseVisits: {
          settings: {
            activeRecord,
            changedItems: { changed, values },
          },
        },
        user: { scopePrefix },
      } = thunkAPI.getState();

      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      const days_between_visits = toUpdate.organization_default
        ? null
        : toUpdate.days_between_visits;
      let result = null;
      if (activeRecord.id) {
        result = await updateHouseVisitSettings({
          scopePrefix,
          patientId,
          settings: { ...toUpdate, id: activeRecord.id, days_between_visits },
        });
      } else {
        result = await createHouseVisitSettings({
          scopePrefix,
          patientId,
          settings: { ...toUpdate, days_between_visits },
        });
      }
      thunkAPI.dispatch(reload());
      return result;
    },
    "save_house_visit_settings",
    "save_house_visit_settings_success",
    "save_house_visit_settings_error"
  )
);

const initialPageSize = 100;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "date",
      order: "descend",
    },
    items: [],
  },
  templates: {
    items: [],
  },
  settings: {
    activeRecord: {},
    changedItems: {
      changed: [],
      values: [],
    },
  },
};

const houseVisitsSlice = createSlice({
  name: "houseVisits",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.settings.changedItems.changed = changed;
      state.settings.changedItems.values = values;
    },
    resetSettings(state, action) {
      state.settings = {
        activeRecord: {},
        error: null,
        changedItems: { changed: [], values: [] },
      };
    },
    reset(state, action) {
      state.page = initialState.page;
      state.settings = initialState.settings;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    "houseVisit/update/fulfilled": onItemChanged,
    "houseVisit/delete/fulfilled": (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.settings.activeRecord = data || {};
      state.settings.error = error;
    },
    [commitChanges.rejected]: (state, action) => {
      state.settings.error = action.error;
    },
    [fetchSettings.fulfilled]: (state, { payload: { error, data } }) => {
      state.settings.activeRecord = data || {};
      state.settings.error = error;
    },
    [fetchSettings.rejected]: (state, action) => {
      state.settings.error = action.error;
    },
  },
});

export const { setPage, setChangedFormItems, resetSettings, reset } =
  houseVisitsSlice.actions;

export default houseVisitsSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().houseVisits.page,
    ...params,
  };
  const scopePrefix = getState().user.scopePrefix;
  const patientId = getState().patientDetails.activePatient.id;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix, patientId }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().houseVisits.page));
};
