import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { unflatten } from "flat";
import { updateOrganization, getOrganization } from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import store from "../../redux/store";

const initialState = {
  error: null,
  details: {},
  changedItems: {
    changed: [],
    values: [],
  },
};

export const loadRecord = createAsyncThunk(
  "organization/load",
  async (params, thunkAPI) => {
    return getOrganization(params).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load organization", error);
      }
      return data;
    });
  }
);

export const commitChanges = createAsyncThunk(
  "organization/update",
  withNotifications(
    async (params, thunkAPI) => {
      // const { scopePrefix } = thunkAPI.getState().user;

      const { organization } = thunkAPI.getState();
      const { details } = organization;
      const { id } = details;
      const {
        changedItems: { changed, values },
      } = organization;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});

      return updateOrganization({
        id,
        organization: { ...unflatten(toUpdate) },
      });
    },
    "save_organization",
    "save_organization_success",
    "save_organization_error"
  )
);

const organizationSlice = createSlice({
  name: "organization",
  initialState: initialState,
  reducers: {
    setDetails(state, action) {
      const { record } = action.payload;
      state.details = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    reset(state, action) {
      state.error = null;
      state.details = {};
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.changedItems = { changed: [], values: [] };
      state.details = action.payload;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.details = data;
      state.changedItems = { changed: [], values: [] };
      state.error = error;
    },
  },
});

export const isAllowedFeature = (feature) => {
  const organizationDetails = store.getState().organization.details;
  const features =
    organizationDetails.features &&
    organizationDetails.features.map((i) => i.name);
  return features && features.includes(feature);
};

export const {
  setDetails,
  setChangedFormItems,
  reset,
} = organizationSlice.actions;

export default organizationSlice.reducer;
